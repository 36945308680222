import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Merry Christmas!`}</strong></p>
    <p><em parentName="p">{`(Rest Day)`}</em></p>
    <p><strong parentName="p">{`*`}{`We will be closed today and will reopen at 10:30 on Thursday.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      